import React, { useLayoutEffect, useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Modal from 'react-modal';

import { useIsFontLoaded } from 'src/hooks/useIsFontLoaded';
import { useScrollToTop } from 'src/hooks/useScrollToTop';

import Home from 'src/pages/Home';
import Privacy from 'src/pages/Privacy';
import Legal from 'src/pages/Legal';
import NoPage from 'src/pages/NoPage';
import Landing from 'src/pages/Landing';
import About from 'src/pages/About';
import Services from 'src/pages/Services';

import { RoutesEnum } from 'src/rotues';
import 'src/styles/main.css';
import CookieConsent, { getCookieConsentValue, Cookies } from 'react-cookie-consent';

const App = () => {
  const handleAcceptCookie = () => {
    Cookies.set('CookieConsent', 'true');
  };

  useEffect(() => {
    const isConsent = getCookieConsentValue();
    if (isConsent === 'true') {
      handleAcceptCookie();
    }
  }, []);
  useScrollToTop();
  const { loaded } = useIsFontLoaded();

  useLayoutEffect(() => {
    const root = document.getElementById('root');

    if (loaded && root) {
      root.classList.remove('wf-loading');
    }
  }, [loaded]);

  return (
    <BrowserRouter>
      <Routes>
        <Route path={RoutesEnum.ROOT} element={<Landing />} />
        <Route path={RoutesEnum.HOME} element={<Home />} />
        <Route path={RoutesEnum.LEGAL} element={<Legal />} />
        <Route path={RoutesEnum.PRIVACY} element={<Privacy />} />
        <Route path={RoutesEnum.ABOUT} element={<About />} />
        <Route path={RoutesEnum.SERVICES} element={<Services />} />
        <Route path="*" element={<NoPage />} />
      </Routes>
      <CookieConsent
        location="top"
        buttonText="GOT IT"
        style={{
          background: '#fff',
          color: '#060709',
          boxShadow: '#00000014 5px 5px 20px',
          padding: '30px 50px',
          letterSpacing: '2px',
          alignItems: 'center',
          justifyContent: 'center',
          zIndex: 9999,
        }}
        buttonStyle={{
          background: 'rgb(0 71 187)',
          color: 'white',
          fontSize: '15px',
          height: '40px',
          display: 'block',
          width: '120px',
          borderRadius: '20px',
          fontWeight: '300',
          letterSpacing: '2px',
        }}
        expires={150}
        onAccept={handleAcceptCookie}
      >
        We use only essential cookies to enhance your user experience. By continuing to visit this site you agree to our
        use of cookies.
      </CookieConsent>
    </BrowserRouter>
  );
};

Modal.setAppElement('#root');

// @ts-ignore
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App />);

window.onerror = function (message, source, lineno, colno, error) {
  console.log('Global error:', { message, source, lineno, colno, error });
  // You can also send this to your error tracking service
};
